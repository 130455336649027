import { toZonedTime } from 'date-fns-tz';

export enum Holidays {
  ValentinesDay = 'valentines_day',
}

const timezone = 'Europe/Berlin';

export default function useHolidayVibes () {
  const ongoingHoliday = ref<Holidays | null>(null);

  const holidaySchedules = {
    [Holidays.ValentinesDay]: {
      start: toZonedTime(Date.UTC(2025, 1, 10, 0, 0, 0), timezone),
      end: toZonedTime(Date.UTC(2025, 1, 16, 0, 0, 0), timezone),
    },
  };

  const updateOngoingHoliday = () => {
    const today = toZonedTime(new Date(), timezone);

    for (const [holiday, { start, end }] of Object.entries(holidaySchedules)) {
      if (today >= start && today <= end) {
        ongoingHoliday.value = holiday as Holidays;
        return;
      }
    }

    ongoingHoliday.value = null;
  };

  return {
    ongoingHoliday,
    updateOngoingHoliday,
  };
}